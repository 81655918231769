import React from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../../components/buttons/buttons";
import { ReactComponent as ArrowIconBlue } from "../../assets/svg2/arrow-icon-blue.svg";
import { ReactComponent as DotsFunSectionDesktop } from "../../assets/svg2/dots-fun-section-desktop.svg";
import { ReactComponent as DotsFunSectionMobile } from "../../assets/svg2/dots-fun-section-mobile.svg";
import FunImage1 from "../../assets/img2/fun-image-1.png";
import FunImage2 from "../../assets/img2/fun-image-2.png";
import FunImage3 from "../../assets/img2/fun-image-3.png";
import FunImage4 from "../../assets/img2/fun-image-4.png";
import FunImage5 from "../../assets/img2/fun-image-5.png";

function Section4() {
  const { width } = useWindowResize();

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="w-full bg-skatecityng-blue-plush">
        <div className="absolute z-20 px-[80px] py-[80px] w-full flex items-center justify-center">
          <div>
            <div className="px-[80px] w-full flex items-center justify-center">
              <span>
                <p className="text-[40px] leading-[54px] tracking-wider font-NewyearGoo text-skatecityng-purple text-center">
                  See the Fun in Motion!
                </p>
                <div className="w-[692px] my-[16px] text-center">
                  <p className="text-[16px] leading-[160%] font-OpenSansRegular">
                    Skating, gaming, eating, have your events and shop merchandise at SkateCity. So why wait? Join us
                    today and experience the thrill of SkateCity, we provide it all, come roll with it!
                  </p>
                </div>
              </span>
            </div>

            <div className="mt-[48px] w-full flex items-center justify-center">
              <div className="w-full absolute flex justify-between px-[80px]">
                <Buttons title="Previous" icon={<ArrowIconBlue className="left-0" />} />
                <Buttons title="Next" icon={<ArrowIconBlue className="right-0 rotate-180" />} />
              </div>
              <div className="grid grid-flow-col grid-rows-2 gap-[20px]">
                {/* Large Vertical Image */}
                <div className="row-span-2 w-[470px]">
                  <img src={FunImage1} alt="Fun Skate City 1" className="w-full h-[662px] object-cover rounded-[8px]" />
                </div>

                {/* Smaller Square Images */}
                <div className="w-[321px]">
                  <img src={FunImage2} alt="Fun Skate City 2" className="w-full h-[321px] object-cover rounded-[8px]" />
                </div>
                <div className="w-[321px]">
                  <img src={FunImage4} alt="Fun Skate City 4" className="w-full h-[321px] object-cover rounded-[8px]" />
                </div>
                <div className="w-[321px]">
                  <img src={FunImage3} alt="Fun Skate City 3" className="w-full h-[321px] object-cover rounded-[8px]" />
                </div>
                <div className="w-[321px]">
                  <img src={FunImage5} alt="Fun Skate City 5" className="w-full h-[321px] object-cover rounded-[8px]" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <DotsFunSectionDesktop className="bg-cover relative bg-repeat w-[862px] h-[983px]" />
        </div>
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="w-full bg-skatecityng-blue-plush">
        <div className="absolute z-20 px-[24px] py-[80px] w-full flex items-center justify-center">
          <div>
            <div className="px-[24px] w-full flex items-center justify-center">
              <span>
                <p className="text-[32px] leading-[100%] tracking-wider font-NewyearGoo text-skatecityng-purple text-center">
                  See the Fun in Motion!
                </p>
                <div className="my-[16px] text-center">
                  <p className="text-[13px] leading-[160%] font-OpenSansRegular">
                    Skating, gaming, eating, have your events and shop merchandise at SkateCity. So why wait? Join us
                    today and experience the thrill of SkateCity, we provide it all, come roll with it!
                  </p>
                </div>
              </span>
            </div>

            <div className="mt-[48px] flex items-center justify-center">
              <div className="grid grid-flow-row grid-cols-2 gap-[10px]">
                {/* Large Vertical Image */}
                <div className="col-span-2 -[380px]">
                  <img src={FunImage1} alt="Fun Skate City 1" className="w-full h-[343px] object-cover rounded-[9px]" />
                </div>

                {/* Smaller Square Images */}
                <div className="-[185px]">
                  <img src={FunImage2} alt="Fun Skate City 2" className="w-full h-[185px] object-cover rounded-[9px]" />
                </div>
                <div className="-[185px]">
                  <img src={FunImage4} alt="Fun Skate City 4" className="w-full h-[185px] object-cover rounded-[9px]" />
                </div>
                <div className="-[185px]">
                  <img src={FunImage3} alt="Fun Skate City 3" className="w-full h-[185px] object-cover rounded-[9px]" />
                </div>
                <div className="-[185px]">
                  <img src={FunImage5} alt="Fun Skate City 5" className="w-full h-[185px] object-cover rounded-[9px]" />
                </div>
              </div>
            </div>

            <div className="w-full mt-[16px] flex items-center justify-center">
              <span>
                <div className="w-[104px] flex items-center justify-between">
                  <Buttons title="Previous" icon={<ArrowIconBlue className="left-0 w-[40px]" />} />
                  <Buttons title="Next" icon={<ArrowIconBlue className="right-0 rotate-180 w-[40px]" />} />
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <DotsFunSectionMobile className="bg-cover relative bg-repeat -[428px] -[1181px]" />
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default Section4;
