import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as SkateCityLogo } from "../../assets/svg2/skate-city-logo.svg";
import { ReactComponent as PinIcon } from "../../assets/svg2/pin-icon.svg";
import { ReactComponent as PhoneIconBlue } from "../../assets/svg2/phone-icon-blue.svg";
import { ReactComponent as EmailIconBlue } from "../../assets/svg2/email-icon-blue.svg";
import { ReactComponent as Instagram } from "../../assets/svg2/Instagram.svg";
import { ReactComponent as Twitter } from "../../assets/svg2/Twitter.svg";
import { ReactComponent as Facebook } from "../../assets/svg2/Facebook.svg";

function Footer() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="w-full pt-[50px] pb-[24px] bg-skatecityng-faded-white">
        <div className="h-full w-full mb-[48px] flex items-center justify-center">
          <div className="h-full w-full  flex justify-evenly">
            <div className="">
              <SkateCityLogo title="Skate City" className="w-[220px] h-[220px]" />
            </div>

            <div>
              <p className="mb-3 font-NewyearGoo tracking-wider text-[25px] leading-[37px] text-skatecityng-purple">
                Quick links
              </p>
              <nav>
                <ol>
                  <li className="mb-4">
                    <button type="button" title="Play" onClick={() => navigate(NonAuthRoutes.play)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Play
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button type="button" title="Munch" onClick={() => navigate(NonAuthRoutes.munch)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Munch
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button type="button" title="Plan an event" onClick={() => navigate(NonAuthRoutes.contact)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Plan an event
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button type="button" title="Become a member" onClick={() => navigate(NonAuthRoutes.ourStory)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Become a member
                      </p>
                    </button>
                  </li>
                  <li>
                    <button type="button" title="Partner with us" onClick={() => navigate(NonAuthRoutes.ourStory)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Partner with us
                      </p>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <p className="mb-3 font-NewyearGoo tracking-wider text-[25px] leading-[37px] text-skatecityng-purple">
                visit us
              </p>
              <div>
                <span className="flex items-center">
                  <p className="font-OpenSansSemiBold text-skatecityng-blue text-[16px] leading-[100%] ">
                    Purple Lekki Mall
                  </p>
                  <PinIcon className="ml-[4px] w-[24px] h-[24px] " />
                </span>
                <p className="font-OpenSansRegular mt-[4px] w-[200px] text-skatecityng-font-black text-[13px] leading-[18px] ">
                  2 Purple Way, off Freedom way, Lekki 1, Lagos NG
                </p>
              </div>
              <div className="mt-[40px] ">
                <span className="flex items-center">
                  <p className="font-OpenSansSemiBold text-skatecityng-blue text-[16px] leading-[100%] ">
                    Purple Maryland Mall
                  </p>
                  <PinIcon className="ml-[4px] w-[24px] h-[24px] " />
                </span>
                <p className="font-OpenSansRegular mt-[4px] w-[200px] text-skatecityng-font-black text-[13px] leading-[18px] ">
                  350-360 Ikorodu Road, Maryland, Lagos NG
                </p>
              </div>
            </div>

            <div>
              <p className="mb-3 font-NewyearGoo tracking-wider  text-[25px] leading-[37px] text-skatecityng-purple">
                Contact us
              </p>
              <span className="flex items-center mb-[9px] ">
                <PhoneIconBlue className="w-[24px] h-[24px] mr-[8px] " />
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  0809 947 3333
                </p>
              </span>
              <span className="flex items-center mb-[9px] ">
                <PhoneIconBlue className="w-[24px] h-[24px] mr-[8px] " />
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  0809 947 3333
                </p>
              </span>

              <span className="flex items-center mb-[9px] ">
                <EmailIconBlue className="w-[24px] h-[24px] mr-[8px] " />
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  info@skatecityng.com
                </p>
              </span>

              <div className="mt-[40px] ">
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  Or follow for latest updates
                </p>
                <div className="flex items-center justify-evenly w-[118px] mt-[8px] ">
                  <Instagram className="w-[32px] h-[32px]" />
                  <Twitter className="w-[32px] h-[32px]" />
                  <Facebook className="w-[32px] h-[32px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] mx-[110px] bg-[#3C6791] opacity-50" />
        <div className="h-[73px] flex items-center justify-center">
          <div>
            <p className="mt-6 font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-purple text-center ">
              © Copyright 2025 Blacksam. Trademarks and brands are the property of Blacksam Ltd
            </p>
          </div>
        </div>
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="h-[1039px] p-[24px] text-center bg-skatecityng-faded-white">
        <div className="flex items-center justify-center mt-[16px]">
          <div>
            <div className="flex items-center justify-center w-full">
              <SkateCityLogo className="w-[120px] h-[120px]" />
            </div>

            <div>
              <p className="mt-[48px] mb-[16px] font-NewyearGoo tracking-wider text-[25px] leading-[37px] text-skatecityng-purple">
                Quick links
              </p>
              <nav>
                <ol>
                  <li className="mb-4">
                    <button type="button" title="Play" onClick={() => navigate(NonAuthRoutes.play)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Play
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button type="button" title="Munch" onClick={() => navigate(NonAuthRoutes.munch)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Munch
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button type="button" title="Plan an event" onClick={() => navigate(NonAuthRoutes.contact)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Plan an event
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button type="button" title="Become a member" onClick={() => navigate(NonAuthRoutes.ourStory)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Become a member
                      </p>
                    </button>
                  </li>
                  <li>
                    <button type="button" title="Partner with us" onClick={() => navigate(NonAuthRoutes.ourStory)}>
                      <p className=" font-OpenSansRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Partner with us
                      </p>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <p className="mt-[48px] mb-[16px] font-NewyearGoo tracking-wider text-[25px] leading-[37px] text-skatecityng-purple">
                visit us
              </p>
              <div>
                <span className="flex items-center justify-center">
                  <p className="font-OpenSansSemiBold text-skatecityng-blue text-[16px] leading-[16px] ">
                    Purple Lekki Mall
                  </p>
                  <PinIcon className="ml-[4px] w-[24px] h-[24px] " />
                </span>
                <p className="font-OpenSansRegular mt-[4px] w-[200px] text-skatecityng-font-black text-[13px] leading-[16px] ">
                  2 Purple Way, off Freedom way, Lekki 1, Lagos NG
                </p>
              </div>
              <div className="mt-[40px] ">
                <span className="flex items-center justify-center">
                  <p className="font-OpenSansSemiBold text-skatecityng-blue text-[16px] leading-[16px] ">
                    Purple Maryland Mall
                  </p>
                  <PinIcon className="ml-[4px] w-[24px] h-[24px] " />
                </span>
                <p className="font-OpenSansRegular mt-[4px] w-[200px] text-skatecityng-font-black text-[13px] leading-[16px] ">
                  350-360 Ikorodu Road, Maryland, Lagos NG
                </p>
              </div>
            </div>

            <div>
              <p className="mt-[48px] mb-[16px] font-NewyearGoo tracking-wider  text-[25px] leading-[37px] text-skatecityng-purple">
                Contact us
              </p>
              <span className="flex items-center justify-center mb-[9px] ">
                <PhoneIconBlue className="w-[24px] h-[24px] mr-[8px] " />
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  0809 947 3333
                </p>
              </span>
              <span className="flex items-center justify-center mb-[9px] ">
                <PhoneIconBlue className="w-[24px] h-[24px] mr-[8px] " />
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  0809 947 3333
                </p>
              </span>

              <span className="flex items-center justify-center mb-[9px] ">
                <EmailIconBlue className="w-[24px] h-[24px] mr-[8px] " />
                <p className="font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-black">
                  info@skatecityng.com
                </p>
              </span>

              <div className="mt-[40px] w-full ">
                <p className="font-OpenSansRegular text-[13px] leading-[100%] opacity-[50%] text-skatecityng-font-black">
                  Or follow for latest updates
                </p>
                <div className="flex items-center justify-center w-full">
                  <div className="flex items-center justify-between w-[114px] mt-[8px] ">
                    <Instagram className="w-[32px] h-[32px]" />
                    <Twitter className="w-[32px] h-[32px]" />
                    <Facebook className="w-[32px] h-[32px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] mt-[24px] bg-[#3C6791] opacity-50" />
        <div className="h-[75px] flex items-center justify-center">
          <div>
            <p className="mb-4 mt-6 text-center px-[20px] font-OpenSansRegular text-[13px] leading-[16.5px] text-skatecityng-purple">
              © Copyright 2025 Blacksam. Trademarks and brands are the property of Blacksam Ltd
            </p>
          </div>
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default Footer;
