import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { CartProvider } from "./cartContext";
import { NonAuthRoutes } from "./urls";
import { ReactComponent as SpinnerIcon } from "../assets/svg2/spinner-icon.svg";
import { ReactComponent as SkateCityText } from "../assets/svg2/skate-city-text.svg";
import LandingPage from "../pages/landingPage/landingPage";
// import LandingPage from "../pages/landingPage/coming-soon";

// const LazyHome = React.lazy(() => import("../pages/landingPage/landingPage"));
const LazyOurStory = React.lazy(() => import("../pages/ourStory/ourStory"));
const LazyPlay = React.lazy(() => import("../pages/play/play"));
const LazyMunch = React.lazy(() => import("../pages/munch/munch"));
const LazyContact = React.lazy(() => import("../pages/contact/contact"));
const LazyCart = React.lazy(() => import("../pages/cart/cart"));

function Routers() {
  return (
    <CartProvider>
      <Suspense
        fallback={
          <div className="flex justify-center items-center w-screen h-screen">
            <div>
              <div className="flex justify-center items-center w-screen">
                <SpinnerIcon className="spinning w-[50px]" />
              </div>
              <div className="flex justify-center items-center w-screen">
                <SkateCityText className="w-[150px]" />
              </div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route exact path={NonAuthRoutes.landingPage} element={<LandingPage />} />
          {/* <Route path={NonAuthRoutes.home} element={<LazyHome />} /> */}
          <Route path={NonAuthRoutes.ourStory} element={<LazyOurStory />} />
          <Route path={NonAuthRoutes.play} element={<LazyPlay />} />
          <Route path={NonAuthRoutes.munch} element={<LazyMunch />} />
          <Route path={NonAuthRoutes.contact} element={<LazyContact />} />
          <Route path={NonAuthRoutes.cart} element={<LazyCart />} />
        </Routes>
      </Suspense>
    </CartProvider>
  );
}
export default Routers;
