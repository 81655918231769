import React from "react";
import { useNavigate } from "react-router-dom";
import { useWindowResize } from "../../helpers/useWindowResize";
import CloudinaryImage from "../../components/cloudinaryImage/cloudinaryImage";
import { NonAuthRoutes } from "../../helpers/urls";
import Buttons from "../../components/buttons/buttons";

function ReusableComponentDesktop({ image, cardName, cardSession, cardDetails }) {
  return (
    <div className="flex-none w-[420px] mr-[64px]">
      <div className="h-[300px] w-[420px] mb-[16px] rounded-[10px]">{image}</div>
      <div className="flex items-center justify-between mb-[8px]">
        <p className="font-NewyearGoo text-[24px] leading-[100%] tracking-wider text-skatecityng-blue">{cardName}</p>
        <Buttons
          title={cardSession}
          text={cardSession}
          className="w-[100px] h-[26px] rounded-[100px] text-[13px] leading-[100%] border-[1px] font-OpenSansRegular
              text-skatecityng-blue bg-skatecityng-blue-plush border-skatecityng-blue hover:border-skatecityng-purple"
        />
      </div>
      <p className="font-OpenSansRegular text-[16px] leading-[160%] text-skatecityng-font-black">{cardDetails}</p>
    </div>
  );
}

function ReusableComponentMobile({ image, cardName, cardSession, cardDetails }) {
  return (
    <div className="flex-none w-[308px] mr-[40px]">
      <div className="h-[220px] w-[308px] mb-[16px] rounded-[10px]">{image}</div>
      <div className="flex items-center justify-between mb-[8px]">
        <p className="font-NewyearGoo text-[18px] leading-[100%] tracking-wider text-skatecityng-blue">{cardName}</p>
        <Buttons
          title={cardSession}
          text={cardSession}
          className="w-[90px] h-[23px] rounded-[100px] text-[11px] leading-[100%] border-[1px] font-OpenSansRegular
              text-skatecityng-blue bg-skatecityng-blue-plush border-skatecityng-blue hover:border-skatecityng-purple"
        />
      </div>
      <p className="font-OpenSansRegular text-[13px] leading-[160%] text-skatecityng-font-black">{cardDetails}</p>
    </div>
  );
}

function Section3() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="w-full">
        <div className="px-[80px] pt-[164px]">
          <div className="flex items-center justify-center">
            <div>
              <p className="text-[40px] leading-[54px] tracking-wider font-NewyearGoo text-skatecityng-purple text-center">
                Our Bestsellers
              </p>
              <p className="text-[16px] leading-[160%] w-[992px] font-OpenSansRegular text-center my-[16px]">
                Don&apos;t wait another day to indulge your gaming passion - gamers from age 4+ and all skill levels are
                welcome to indulge in their passion. With our curation of the greatest games, we guarantee an
                unforgettable gaming experience at SkateCity.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center mb-[64px]">
            <Buttons
              title="Explore Activities"
              text="Explore Activities"
              className="w-[181px] h-[50px] rounded-[100px] leading-[26px] text-[16px] mt-[16px] border-[1px] font-OpenSansSemiBold
                  text-skatecityng-font-white  bg-skatecityng-blue hover:border-skatecityng-purple "
              onClick={() =>
                navigate(NonAuthRoutes.play, {
                  state: { sectionName: "activities" },
                })
              }
            />
          </div>

          <div className="flex flex-nowrap overflow-x-auto mb-[140px] pb-[20px]">
            <ReusableComponentDesktop
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/wdy7qpugdh3kixofs2bd"
                  alt="Skating"
                />
              }
              cardName="Skating"
              cardSession="45 minutes"
              cardDetails="Show off your skills on wheels in a safe and controlled environment. 
              Perfect for experienced and beginners skaters."
            />
            <ReusableComponentDesktop
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/p567auwjpiaeux2nyvke"
                  alt="VR 360"
                />
              }
              cardName="VR 360"
              cardSession="1 session"
              cardDetails="Enter an immersive virtual experience, with 360-degree views and interactive gameplay."
            />
            <ReusableComponentDesktop
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/dzb6og6hebvvt2gc0puf"
                  alt="Hoverboard"
                />
              }
              cardName="Hoverboard"
              cardSession="10 minutes"
              cardDetails="Ride on a self-balancing hoverboard and perform exciting tricks and manoeuvres in a safe and controlled environment."
            />
            <ReusableComponentDesktop
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/gnolh2qzhivk82qoop4d"
                  alt="Snooker"
                />
              }
              cardName="Snooker"
              cardSession="1 game"
              cardDetails="Strategise and aim to score more points than your opponent by pocketing coloured balls in a game of snooker."
            />
            <ReusableComponentDesktop
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/e4fcujpvjvmejai6agdd"
                  alt="Playstation 5"
                />
              }
              cardName="Playstation 5"
              cardSession="1 game"
              cardDetails="Dive into stunning visuals and fast-paced action on the PlayStation 5. Challenge friends or go solo in a thrilling, high-speed adventure."
            />
          </div>
        </div>
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="w-full">
        <div className="px-[24px] pt-[140px]">
          <p className="text-[32px] leading-[100%] tracking-wider font-NewyearGoo text-skatecityng-purple text-center">
            Our Bestsellers
          </p>
          <p className="text-[13px] leading-[160%] font-OpenSansRegular text-center my-[16px]">
            Don&apos;t wait another day to indulge your gaming passion - gamers from age 4+ and all skill levels are
            welcome to indulge in their passion. With our curation of the greatest games, we guarantee an unforgettable
            gaming experience at SkateCity.
          </p>
          <div className="flex items-center justify-center mb-[64px]">
            <Buttons
              title="Explore Activities"
              text="Explore Activities"
              className="w-[156px] h-[50px] rounded-[100px] text-[13px] leading-[160%] mt-[16px] border-[1px] font-OpenSansSemiBold
                  text-skatecityng-font-white  bg-skatecityng-blue hover:border-skatecityng-purple "
              onClick={() =>
                navigate(NonAuthRoutes.play, {
                  state: { sectionName: "activities" },
                })
              }
            />
          </div>

          <div className="flex flex-nowrap overflow-x-auto mb-[70px] pb-[20px]">
            <ReusableComponentMobile
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/wdy7qpugdh3kixofs2bd"
                  alt="Skating"
                />
              }
              cardName="Skating"
              cardSession="45 minutes"
              cardDetails="Show off your skills on wheels in a safe and controlled environment. 
              Perfect for experienced and beginners skaters."
            />
            <ReusableComponentMobile
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/p567auwjpiaeux2nyvke"
                  alt="Skating"
                />
              }
              cardName="VR 360"
              cardSession="1 session"
              cardDetails="Enter an immersive virtual experience, with 360-degree views and interactive gameplay."
            />
            <ReusableComponentMobile
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/dzb6og6hebvvt2gc0puf"
                  alt="Skating"
                />
              }
              cardName="Hoverboard"
              cardSession="10 minutes"
              cardDetails="Ride on a self-balancing hoverboard and perform exciting tricks and manoeuvres in a safe and controlled environment."
            />
            <ReusableComponentMobile
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/gnolh2qzhivk82qoop4d"
                  alt="Skating"
                />
              }
              cardName="Snooker"
              cardSession="1 game"
              cardDetails="Strategise and aim to score more points than your opponent by pocketing coloured balls in a game of snooker."
            />
            <ReusableComponentMobile
              image={
                <CloudinaryImage
                  src="https://res.cloudinary.com/debmx1cb4/image/upload/f_auto,q_auto:best/v1/PLAY/e4fcujpvjvmejai6agdd"
                  alt="Skating"
                />
              }
              cardName="Playstation 5"
              cardSession="1 game"
              cardDetails="Dive into stunning visuals and fast-paced action on the PlayStation 5. Challenge friends or go solo in a thrilling, high-speed adventure."
            />
          </div>
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default Section3;
