/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import jsonp from "jsonp";
import queryString from "query-string";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../buttons/buttons";
import Inputs from "../inputs/inputs";
import { ReactComponent as LoadingIconPurple } from "../../assets/svg/loading-icon-purple.svg";

function EmailSubscribe({ setEmailResponseOpen, setIsErrorResponse }) {
  const { width } = useWindowResize();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [emptyInputWarning, setEmptyInputWarning] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const subscribeToExclusiveOffers = e => {
    e.preventDefault();
    if (email.length === 0 || firstName.length === 0 || lastName.length === 0) {
      setEmptyInputWarning(true);
      setTimeout(() => {
        setEmptyInputWarning(false);
      }, 3000);
    } else {
      setBtnIsLoading(true);
      const formData = {
        EMAIL: email,
        FNAME: firstName,
        LNAME: lastName,
      };
      jsonp(
        `https://skatecityng.us14.list-manage.com/subscribe/post-json?u=0c39c72ec1b2cea6c7c70d9c5&amp;id=5b1d1935c8&${queryString.stringify(
          formData
        )}`,
        { param: "c" },
        (err, data) => {
          // console.log("data:", data.result);
          // console.log("data:", data.msg);
          if (data.result === "success") {
            setBtnIsLoading(false);
            setSuccess(true);
            setSuccessMessage(data.msg);
            setEmailResponseOpen(true);
            setIsErrorResponse(false);
            setTimeout(() => {
              setEmail("");
              setFirstName("");
              setLastName("");
              setSuccess(false);
              setSuccessMessage("");
            }, 10000);
          } else {
            setWarning(true);
            setWarningMessage(data.msg);
            setEmailResponseOpen(true);
            setIsErrorResponse(true);
            setBtnIsLoading(false);
            setTimeout(() => {
              setWarning(false);
              setWarningMessage("");
            }, 10000);
          }
        }
      );
    }
  };

  /** displays Warning */
  const displayEmptyInputWarningDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="mt-6 mb-4 px-4 py-2 w-1/2 rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          All entries and required to subscribe to exclusice offers...
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayEmptyInputWarningMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          All entries and required to subscribe to exclusice offers...
        </p>
      </div>
    );
  };

  /** displays Success */
  const displaySuccessDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="mt-6 mb-4 px-4 py-2 w-1/2 rounded font-PoppinsLight text-xs text-center border-2 text-green-500  border-green-500 bg-green-100">
          {successMessage}
        </p>
      </div>
    );
  };

  /** displays Success */
  const displaySuccessMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-PoppinsLight text-xs text-center border-2 text-green-500  border-green-500 bg-green-100">
          {successMessage}
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayWarningDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="mt-6 mb-4 px-4 py-2 w-1/2 rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          {warningMessage}
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayWarningMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          {warningMessage}
        </p>
      </div>
    );
  };

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="w-full py-[64px] bg-gradient-to-b from-[#028DD6] to-[#02669A]">
        <div className="px-20">
          <div>
            <p className="mb-6 text-center text-skatecityng-font-white font-NewyearGoo text-[40px] tracking-wider leading-[100%]">
              Sign Up for Exclusive Offers!
            </p>
            <p className="mb-6 mt-6 text-center text-skatecityng-font-white font-OpenSansRegular text-[16px] leading-[160%] px-[10%]">
              Stay in the loop with skating events, exclusive discounts and updates on latest games and other
              activities. You&apos;ll be the first to know about our newest attractions and games, don&apos;t miss out
              on these exciting opportunities - sign up now!
            </p>
          </div>
          <div className="mt-[64px]">
            <form className="flex items-center justify-evenly w-full">
              <Inputs
                label="firstName"
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                className="rounded-[200px] w-[282px] h-[47px] bg-transparent text-skatecityng-font-white mr-6 placeholder-[#4e94b8] border border-white "
              />
              <Inputs
                label="lasttName"
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                className="rounded-[200px] w-[282px] h-[47px] bg-transparent text-skatecityng-font-white mr-6 placeholder-[#4e94b8] border border-white "
              />
              <Inputs
                label="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="rounded-[200px] w-[432px] h-[47px] bg-transparent text-skatecityng-font-white mr-6 placeholder-[#4e94b8] border border-white "
              />
              {btnIsLoading ? (
                <Buttons
                  className="px-6 h-[50px] text-[16px] leading-[26px] text-skatecityng-purple bg-white hover:bg-[#ebecf4] rounded-[100px]"
                  title="Loading"
                  icon={<LoadingIconPurple className="spinning" />}
                />
              ) : (
                <Buttons
                  onClick={e => subscribeToExclusiveOffers(e)}
                  className="px-6 h-[50px] text-[16px] leading-[26px] font-OpenSansSemiBold text-skatecityng-purple bg-skatecityng-background-white hover:bg-[#ebecf4] rounded-[100px]"
                  title="submit"
                  text="Submit"
                />
              )}
            </form>
            {emptyInputWarning ? displayEmptyInputWarningDesktop() : <span />}
            {warning ? displayWarningDesktop() : <span />}
            {success ? displaySuccessDesktop() : <span />}
          </div>
        </div>
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="w-full py-[64px] bg-gradient-to-b from-[#028DD6] to-[#02669A]">
        <div className="px-[24px]">
          <div>
            <p className="mb-6 text-center text-skatecityng-font-white font-NewyearGoo text-[32px] leading-[100%] tracking-wider">
              Sign Up for Exclusive Offers!
            </p>
            <p className="mb-6 text-center text-skatecityng-font-white font-OpenSansRegular text-[13px] leading-[160%]">
              Stay in the loop with skating events, exclusive discounts and updates on latest games and other
              activities. You&apos;ll be the first to know about our newest attractions and games, don&apos;t miss out
              on these exciting opportunities - sign up now!
            </p>
          </div>
          <div className="mt-[40px]">
            <form>
              <Inputs
                label="firstName"
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                className="rounded-[100px] w-full h-[47px] bg-transparent text-skatecityng-font-white mb-6 placeholder-[#4e94b8] border border-white "
              />
              <Inputs
                label="lasttName"
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                className="rounded-[100px] w-full h-[47px] bg-transparent text-skatecityng-font-white mb-6 placeholder-[#4e94b8] border border-white "
              />
              <Inputs
                label="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="rounded-[100px] w-full h-[47px] bg-transparent text-skatecityng-font-white mb-6 placeholder-[#4e94b8] border border-white "
              />
              {emptyInputWarning ? displayEmptyInputWarningMobile() : <span />}
              {warning ? displayWarningMobile() : <span />}
              {success ? displaySuccessMobile() : <span />}
              <div className="flex items-center justify-center w-full">
                {btnIsLoading ? (
                  <Buttons
                    className="px-6 h-[50px] w-[125px] text-[16px] leading-[26px] text-skatecityng-purple bg-white hover:bg-[#ebecf4] rounded-[100px]"
                    title="Loading"
                    icon={<LoadingIconPurple className="spinning" />}
                  />
                ) : (
                  <Buttons
                    onClick={e => subscribeToExclusiveOffers(e)}
                    className="px-6 h-[50px] w-[125px] text-[16px] leading-[26px] font-OpenSansSemiBold text-skatecityng-purple bg-skatecityng-background-white hover:bg-[#ebecf4] rounded-[100px]"
                    title="submit"
                    text="Submit"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default EmailSubscribe;
