import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { ReactComponent as SkateCityLogo } from "../../assets/svg2/skate-city-logo.svg";
import { ReactComponent as MenuBarIcon } from "../../assets/svg2/menu-bar-icon.svg";
import { ReactComponent as CartIcon } from "../../assets/svg2/cart-icon.svg";
import { ReactComponent as CartIconNumber } from "../../assets/svg2/cart-icon-number.svg";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../buttons/buttons";
import { CartContext } from "../../helpers/cartContext";

function Header({ setMobileMenuOpen, isIntersecting }) {
  const location = useLocation();
  const { width } = useWindowResize();
  const navigate = useNavigate();
  const { cartCount } = useContext(CartContext);

  /** Diplay Header On Desktop View */
  const headerDesktopView = () => {
    return (
      <div
        className={`absolute w-full transition-colors duration-300 ${
          isIntersecting ? "bg-transparent" : "bg-skatecityng-background-white"
        }`}
      >
        <div className="h-[115px] px-20 flex items-center justify-between">
          {isIntersecting ? (
            <span />
          ) : (
            <Buttons
              title="Skate City"
              icon={<SkateCityLogo />}
              onClick={() => navigate(NonAuthRoutes.landingPage)}
              className="h-[70px]"
            />
          )}

          <div className="flex">
            <nav className="font-PoppinsRegular text-xl text-white text-center">
              <ul className="flex items-center justify-between">
                <li>
                  <Buttons
                    title="Home"
                    text="Home"
                    onClick={() => navigate(NonAuthRoutes.landingPage)}
                    className="mr-[24px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                     text-center hover:border-skatecityng-purple hover:border-[1px] w-[107px] h-[46px] bg-skatecityng-blue "
                  />
                </li>
                <li>
                  <Buttons
                    title="Our Story"
                    text="Our Story"
                    onClick={() => navigate(NonAuthRoutes.ourStory)}
                    className={`mr-[24px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                     text-center hover:border-skatecityng-purple hover:border-[1px] w-[165px] h-[46px] ${
                       location.pathname === NonAuthRoutes.ourStory
                         ? "text-skatecityng-purple"
                         : "bg-skatecityng-yellow"
                     }`}
                  />
                </li>
                <li>
                  <Buttons
                    title="Play"
                    text="Play"
                    onClick={() => navigate(NonAuthRoutes.play)}
                    className={`mr-[24px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                     text-center hover:border-skatecityng-purple hover:border-[1px] w-[101px] h-[46px] ${
                       location.pathname === NonAuthRoutes.play ? "text-skatecityng-purple" : "bg-skatecityng-green"
                     }`}
                  />
                </li>
                <li>
                  <Buttons
                    title="Munch"
                    text="Munch"
                    onClick={() => navigate(NonAuthRoutes.munch)}
                    className={`mr-[24px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                     text-center hover:border-skatecityng-purple hover:border-[1px] w-[123px] h-[46px] ${
                       location.pathname === NonAuthRoutes.munch ? "text-skatecityng-purple" : "bg-skatecityng-orange"
                     }`}
                  />
                </li>
                <li>
                  <Buttons
                    title="Contact"
                    text="Contact"
                    onClick={() => navigate(NonAuthRoutes.contact)}
                    className={`mr-[24px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                     text-center hover:border-skatecityng-purple hover:border-[1px] w-[140px] h-[46px] ${
                       location.pathname === NonAuthRoutes.contact ? "text-skatecityng-purple" : "bg-skatecityng-red"
                     }`}
                  />
                </li>
              </ul>
            </nav>
            {location.pathname === NonAuthRoutes.play || location.pathname === NonAuthRoutes.munch ? (
              <div>
                {cartCount > 0 ? (
                  <div className="relative">
                    <Buttons
                      title="View Cart"
                      icon={<CartIconNumber className="w-[42px] h-[42px]" />}
                      onClick={() => navigate(NonAuthRoutes.cart)}
                    />

                    {/* Red Circle for Cart Count */}
                    {cartCount > 0 && (
                      <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold w-[25px] h-[25px] flex items-center justify-center rounded-full">
                        {cartCount}
                      </span>
                    )}
                  </div>
                ) : (
                  <Buttons
                    title="View Cart"
                    icon={<CartIcon className="w-[42px] h-[42px]" />}
                    onClick={() => navigate(NonAuthRoutes.cart)}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  /** Diplay Header On Mobile View */
  const headerMobileView = () => {
    return (
      <div className="absolute w-full bg-transparent">
        <div
          className={`h-[84px] px-[24px] w-full flex items-center
            ${isIntersecting ? "bg-transparent" : "bg-skatecityng-background-white"}
          `}
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center justify-center">
              <Buttons
                title="Menu"
                icon={<MenuBarIcon className="h-[32px]" />}
                onClick={() => setMobileMenuOpen(true)}
                className="mr-[8px]"
              />

              {isIntersecting ? (
                <span />
              ) : (
                <Buttons
                  title="Skate City"
                  icon={<SkateCityLogo className="h-[52px]" />}
                  onClick={() => navigate(NonAuthRoutes.landingPage)}
                />
              )}
            </div>

            {cartCount > 0 ? (
              <div className="relative">
                <Buttons
                  title="View Cart"
                  icon={<CartIconNumber className="w-[42px] h-[42px]" />}
                  onClick={() => navigate(NonAuthRoutes.cart)}
                />

                {/* Red Circle for Cart Count */}
                {cartCount > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold w-[25px] h-[25px] flex items-center justify-center rounded-full">
                    {cartCount}
                  </span>
                )}
              </div>
            ) : (
              <div className="relative">
                <Buttons
                  title="View Cart"
                  icon={<CartIcon className="w-[42px] h-[42px]" />}
                  onClick={() => navigate(NonAuthRoutes.cart)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <div className="sticky top-0 z-30">{width > 1060 ? headerDesktopView() : headerMobileView()}</div>;
}

export default Header;
