import React from "react";

function Buttons({ title, disabled, className, onClick, text, icon, iconLeft, iconRight }) {
  return (
    <div>
      <button type="button" title={title} disabled={disabled} className={`${className}`} onClick={onClick}>
        <div className="flex justify-center items-center">
          {iconLeft && <span className="mr-2">{iconLeft}</span>}
          {icon && <span className="items-center">{icon}</span>}
          <p>{text}</p>
          {iconRight && <span className="ml-2">{iconRight}</span>}
        </div>
      </button>
    </div>
  );
}

export default Buttons;
