import React, { useEffect } from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../buttons/buttons";
import { ReactComponent as SkateCityBigLogo } from "../../assets/svg2/skate-city-logo.svg";
import { ReactComponent as CancelIconBlue } from "../../assets/svg2/cancel-icon-blue.svg";
import { ReactComponent as DotsEmailSubscribeDesktop } from "../../assets/svg2/dots-email-subscribe-desktop.svg";
import { ReactComponent as DotsEmailSubscribeMobile } from "../../assets/svg2/dots-email-subscribe-mobile.svg";

function EmailResponse({ emailResponseOpen, setEmailResponseOpen, isErrorResponse }) {
  const { width } = useWindowResize();

  useEffect(() => {
    if (setEmailResponseOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [emailResponseOpen]);

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div>
        <div className="fixed z-50 outline-none focus:outline-none">
          <div className="flex items-center justify-center w-screen h-screen">
            <div className="relative w-[960px] rounded-lg bg-white overflow-auto outline-none focus:outline-none">
              <div className="absolute z-10 w-full h-full">
                <div className="w-full absolute flex items-center justify-end p-[24px]">
                  <Buttons title="Close" icon={<CancelIconBlue />} onClick={() => setEmailResponseOpen(false)} />
                </div>
                <div className="flex items-center justify-between w-full h-full px-[40px]">
                  <div className="w-[600px]">
                    <p className="text-skatecityng-blue mb-1 font-OpenSansSemiBold text-[20px] leading-[100%]">
                      {isErrorResponse ? "Oops!" : "Welcome to our"}
                    </p>

                    {isErrorResponse ? (
                      <p className="text-[#E00305] mb-1 font-NewyearGoo text-[40px] leading-[100%] tracking-wider">
                        Something went wrong!
                      </p>
                    ) : (
                      <p className="text-skatecityng-purple mb-1 font-NewyearGoo text-[40px] leading-[100%] tracking-wider">
                        Skatecity community!
                      </p>
                    )}

                    <p className="text-skatecityng-font-black mt-[30px] mb-4 font-OpenSansRegular text-[16px] leading-[160%]">
                      {isErrorResponse
                        ? "This email may be invalid or already exists in our database, please check the email and try again. "
                        : "Thank you for signing up for our mailing service. By subscribing, you'll be the first to know about our skating events, exclusive discounts and updates on latest games and other activities."}
                    </p>
                    <p className="text-skatecityng-font-black mb-[30px] font-PoppinsRegular text-[16px] leading-[26px]">
                      {isErrorResponse
                        ? ""
                        : "Do not forget to add our email address to your contacts list to ensure you receive our emails. If you ever have any questions or feedback, please don't hesitate to reach out."}
                    </p>
                    <p className="text-skatecityng-blue font-OpenSansSemiBold text-[15px] leading-[24px]">
                      {isErrorResponse
                        ? "We can't wait to share updates with you!"
                        : " We are thrilled to have you on board and can't wait to share updates with you!"}
                    </p>
                    {isErrorResponse ? (
                      <Buttons
                        title="Try Again"
                        text="Try Again"
                        className="bg-skatecityng-blue w-[121px] h-[50px] text-[16px] leading-[36px] text-skatecityng-font-white font-OpenSansSemiBold rounded-[100px] mt-[30px] flex items-center justify-center border"
                        onClick={() => setEmailResponseOpen(false)}
                      />
                    ) : (
                      <Buttons
                        title="Continue Browsing"
                        text="Continue Browsing"
                        className="bg-skatecityng-blue w-[195px] h-[50px] text-[16px] leading-[36px] text-skatecityng-font-white font-OpenSansSemiBold rounded-[100px] mt-[30px] flex items-center justify-center border"
                        onClick={() => setEmailResponseOpen(false)}
                      />
                    )}
                  </div>
                  <SkateCityBigLogo className=" w-[250px] h-[330px] " />
                </div>
              </div>

              <div className="flex justify-end">
                <DotsEmailSubscribeDesktop className="bg-cover relative bg-repeat w-[656px] h-[514px]" />
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-80 fixed inset-0 z-40 bg-black" />
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div>
        <div className="fixed z-50 outline-none focus:outline-none">
          <div className="flex flex-col justify-end w-screen h-screen">
            <div
              className={`${
                isErrorResponse ? "h-[70%] pb-[120px]" : "h-[85%] pb-[300px]"
              } relative w-full  rounded-t-[50px] bg-white overflow-auto outline-none focus:outline-none`}
            >
              <div className="absolute  z-10 w-full h-full">
                <div className="w-full absolute flex items-center justify-end p-[16px]">
                  <Buttons title="Close" icon={<CancelIconBlue />} onClick={() => setEmailResponseOpen(false)} />
                </div>
                <div className="flex items-center justify-center mt-[48px]">
                  <SkateCityBigLogo className="w-[125px] h-[165px]" />
                </div>
                <div className="p-[24px]">
                  <p className=" text-skatecityng-blue mb-[12px] font-OpenSansSemiBold text-[16px] leading-[21px]">
                    {isErrorResponse ? "Oops!" : "Welcome to our"}
                  </p>
                  {isErrorResponse ? (
                    <p className="text-[#E00305] mb-1 font-NewyearGoo text-[20px] leading-[30px]">
                      Something went wrong!
                    </p>
                  ) : (
                    <p className="text-skatecityng-purple mb-1 font-NewyearGoo text-[32px] leading-[100%] tracking-wider">
                      Skatecity community!
                    </p>
                  )}
                  <p className="text-skatecityng-font-black mt-[30px] mb-4 font-OpenSansRegular text-[13px] leading-[20px]">
                    {isErrorResponse
                      ? "This email may be invalid or already exists in our database, please check the email and try again. "
                      : "Thank you for signing up for our mailing service. By subscribing, you'll be the first to know about our skating events, exclusive discounts and updates on latest games and other activities."}
                  </p>
                  <p className="text-skatecityng-font-black mb-[30px] font-OpenSansRegular text-[13px] leading-[20px]">
                    {isErrorResponse
                      ? ""
                      : "Do not forget to add our email address to your contacts list to ensure you receive our emails. If you ever have any questions or feedback, please don't hesitate to reach out."}
                  </p>
                  <p className="text-skatecityng-blue font-OpenSansSemiBold text-[13px] leading-[20px]">
                    {isErrorResponse
                      ? "We can't wait to share updates with you!"
                      : " We are thrilled to have you on board and can't wait to share updates with you!"}
                  </p>

                  {isErrorResponse ? (
                    <Buttons
                      title="Try Again"
                      text="Try Again"
                      className="bg-skatecityng-blue w-[107px] h-[50px] text-[13px] leading-[26px] text-skatecityng-font-white font-OpenSansSemiBold rounded-[100px] mt-[30px] flex items-center justify-center border"
                      onClick={() => setEmailResponseOpen(false)}
                    />
                  ) : (
                    <Buttons
                      title="Continue Browsing"
                      text="Continue Browsing"
                      className="bg-skatecityng-blue w-[167px] h-[50px] text-[13px] leading-[26px] text-skatecityng-font-white font-OpenSansSemiBold rounded-[100px] mt-[30px] flex items-center justify-center border"
                      onClick={() => setEmailResponseOpen(false)}
                    />
                  )}
                </div>
              </div>

              <div className="flex -z-50 justify-end">
                <DotsEmailSubscribeMobile className="bg-cover -z-50 relative bg-repeat w-full h-[488px] top-0" />
              </div>
            </div>
          </div>
        </div>

        <div className="opacity-80 fixed inset-0 z-40 bg-black" />
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default EmailResponse;
