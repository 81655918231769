import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useWindowResize } from "../../helpers/useWindowResize";
import { NonAuthRoutes } from "../../helpers/urls";
import Buttons from "../buttons/buttons";
import { ReactComponent as CancelIconPurple } from "../../assets/svg2/cancel-icon-purple.svg";
import { ReactComponent as SkateCityLogo } from "../../assets/svg2/skate-city-logo.svg";
import { ReactComponent as CartIconFade } from "../../assets/svg2/cart-icon-fade.svg";
import { ReactComponent as ArrowRightWhite } from "../../assets/svg2/arrow-right-white.svg";

function MobileMenu({ mobileMenuOpen, setMobileMenuOpen }) {
  const { width } = useWindowResize();
  const navigate = useNavigate();
  const [height, setHeight] = useState("100dvh");

  useEffect(() => {
    const updateHeight = () => {
      setHeight(`${window.innerHeight}px`);
    };

    updateHeight(); // Set initial height
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [mobileMenuOpen]);

  // lets start animation
  const item = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 1,
      },
    },
  };

  return (
    <div>
      {width < 1060 && (
        <AnimatePresence>
          {mobileMenuOpen && (
            <div className="sticky z-50">
              <div className="fixed bg-skatecityng-background-white  w-full">
                <div className="overflow-y-auto" style={{ height }}>
                  <motion.div
                    variants={item}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "100vh", opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    exit="exit"
                  >
                    <div className="p-[24px] bg-skatecityng-blue-plush">
                      <motion.a
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.7 }}
                        exit={{
                          opacity: 0,
                          y: 90,
                          transition: {
                            ease: "easeInOut",
                            delay: 0.9,
                          },
                        }}
                      >
                        <div className="flex justify-end ">
                          <Buttons
                            title="Close"
                            icon={<CancelIconPurple className="h-[32px]" />}
                            onClick={() => setMobileMenuOpen(false)}
                          />
                        </div>

                        <div className="flex items-center justify-center">
                          <SkateCityLogo className="h-[120px] w-[120px]" />
                        </div>
                      </motion.a>
                      <div className="h-[202px] mt-[64px] mb-[56px]">
                        <ul className="grid grid-cols-2 gap-y-[32px] h-[46px] ">
                          <li className="flex justify-center">
                            <motion.a
                              initial={{ y: 80, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ delay: 0.6 }}
                              exit={{
                                opacity: 0,
                                y: 90,
                                transition: {
                                  ease: "easeInOut",
                                  delay: 0.4,
                                },
                              }}
                            >
                              <Buttons
                                title="Home"
                                text="Home"
                                onClick={() => {
                                  navigate(NonAuthRoutes.landingPage);
                                  setMobileMenuOpen(false);
                                }}
                                className="w-[142px] h-[46px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                            text-center text-skatecityng-background-white bg-skatecityng-blue"
                              />
                            </motion.a>
                          </li>
                          <li className="flex justify-center">
                            <motion.a
                              initial={{ y: 80, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ delay: 0.6 }}
                              exit={{
                                opacity: 0,
                                y: 90,
                                transition: {
                                  ease: "easeInOut",
                                  delay: 0.4,
                                },
                              }}
                            >
                              <Buttons
                                title="Our Story"
                                text="Our Story"
                                onClick={() => {
                                  navigate(NonAuthRoutes.ourStory);
                                  setMobileMenuOpen(false);
                                }}
                                className="w-[142px] h-[46px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                              text-center text-skatecityng-background-white bg-skatecityng-yellow"
                              />
                            </motion.a>
                          </li>
                          <li className="flex justify-center">
                            <motion.a
                              initial={{ y: 80, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ delay: 0.6 }}
                              exit={{
                                opacity: 0,
                                y: 90,
                                transition: {
                                  ease: "easeInOut",
                                  delay: 0.4,
                                },
                              }}
                            >
                              <Buttons
                                title="Play"
                                text="Play"
                                onClick={() => {
                                  navigate(NonAuthRoutes.play);
                                  setMobileMenuOpen(false);
                                }}
                                className="w-[142px] h-[46px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                              text-center text-skatecityng-background-white bg-skatecityng-green"
                              />
                            </motion.a>
                          </li>
                          <li className="flex items-center justify-center">
                            <motion.a
                              initial={{ y: 80, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ delay: 0.6 }}
                              exit={{
                                opacity: 0,
                                y: 90,
                                transition: {
                                  ease: "easeInOut",
                                  delay: 0.4,
                                },
                              }}
                            >
                              <Buttons
                                title="Munch"
                                text="Munch"
                                onClick={() => {
                                  navigate(NonAuthRoutes.munch);
                                  setMobileMenuOpen(false);
                                }}
                                className="w-[142px] h-[46px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                              text-center text-skatecityng-background-white bg-skatecityng-orange"
                              />
                            </motion.a>
                          </li>
                          <li className="flex justify-center">
                            <motion.a
                              initial={{ y: 80, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ delay: 0.6 }}
                              exit={{
                                opacity: 0,
                                y: 90,
                                transition: {
                                  ease: "easeInOut",
                                  delay: 0.4,
                                },
                              }}
                            >
                              <Buttons
                                title="Contact"
                                text="Contact"
                                onClick={() => {
                                  navigate(NonAuthRoutes.contact);
                                  setMobileMenuOpen(false);
                                }}
                                className="w-[142px] h-[46px] text-[20px] leading-[22.34px] tracking-wider rounded-[100px] whitespace-nowrap font-NewyearGoo
                              text-center text-skatecityng-background-white bg-skatecityng-red"
                              />
                            </motion.a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <motion.a
                      initial={{ y: 80, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.7 }}
                      exit={{
                        opacity: 0,
                        y: 90,
                        transition: {
                          ease: "easeInOut",
                          delay: 0.1,
                        },
                      }}
                    >
                      <div className="w-full ">
                        <motion.a
                          initial={{ y: 80, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.7 }}
                          exit={{
                            opacity: 0,
                            y: 90,
                            transition: {
                              ease: "easeInOut",
                              delay: 0.3,
                            },
                          }}
                        >
                          <div className="pt-[40px] mb-[1000px] px-[24px]">
                            <p className="text-[13px] leading-[18.2px] font-OpenSansRegular opacity-[60%]">
                              Quick Links
                            </p>
                            <Buttons
                              title="Host an event"
                              text="Host an event"
                              onClick={() => {
                                navigate(NonAuthRoutes.contact);
                                setMobileMenuOpen(false);
                              }}
                              className="mt-[17px] mb-[20px]"
                            />
                            <Buttons
                              title="Become a member"
                              text="Become a member"
                              onClick={() => {
                                navigate(NonAuthRoutes.contact);
                                setMobileMenuOpen(false);
                              }}
                              className="mb-[20px]"
                            />
                            <Buttons
                              title="Partner with us"
                              text="Partner with us"
                              onClick={() => {
                                navigate(NonAuthRoutes.contact);
                                setMobileMenuOpen(false);
                              }}
                              className="mb-[100px]"
                            />
                          </div>
                          <span />
                        </motion.a>
                      </div>
                    </motion.a>
                  </motion.div>
                </div>
              </div>
            </div>
          )}
        </AnimatePresence>
      )}

      <div className="fixed bottom-0 left-0 w-full bg-skatecityng-blue h-[80px] rounded-t-[18px] z-50">
        <div className="flex pt-[14px] ml-[24px]">
          <CartIconFade />
          <div className="ml-[16px]">
            <p className="text-[11px] leading-[21.4px] font-OpenSansRegular opacity-[70%] text-white">
              No items in your cart
            </p>
            <Buttons
              title="View Cart"
              text="View Cart"
              iconRight={<ArrowRightWhite />}
              onClick={() => {
                navigate(NonAuthRoutes.contact);
                setMobileMenuOpen(false);
              }}
              className="text-white text-[16px] leading-[21.79px] mr-[7px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
