import React, { useState } from "react";
import { ReactComponent as LoadingImage } from "../../assets/svg2/loading-image.svg";

function CloudinaryImage({ src, alt, className }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className="relative w-full h-full rounded-[10px]">
      {loading && (
        <div className="absolute inset-0 bg-gray-300 animate-pulse flex items-center justify-center rounded-[10px]">
          <LoadingImage />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`${className} w-full h-full object-cover rounded-[10px] transition-opacity duration-100 ${
          loading ? "opacity-0" : "opacity-100"
        }`}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}

export default CloudinaryImage;
