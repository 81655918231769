export const reviews = [
  {
    id: 0,
    name: "Winifred Amase",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      " I went skating here for the first time and really had a great experience. I went there with zero knowledge of how to goabout it and was able to move from one end to another without assistance at the end. The staff there are helpful, courteous and attentive.",
  },
  {
    id: 1,
    name: "Awoke Ogboe",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "It was my second time here and the visit was fun - skating, helping other people skate, laughing and learning with others. The customer service was alright and the music inside the rink was motivating. If you’d like to learn skating then Skate City’s a good place to start: a good 3k for 45 minutes as of November 2022!",
  },
  {
    id: 2,
    name: "Mojoyinola Balogun",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "This is a mix of fun and everything nice!!! Wait for me as I climb up the leader to becoming a pro!I definitely recommend you go with comfortable clothing and water. Stanley and victor are a delight to work with. Patient and persistent to help you skate right 💕",
  },
  {
    id: 3,
    name: "Tomilola Olukoya",
    starActive: [1, 2, 3, 4, 5],
    starInactive: [],
    review:
      "SkateCity Ng is one of the best places to learn skating and even for experts, the attendants are friendly and helpful, their skate shoes are comfortable but they need to change their helmet. If you go to SkateCity with no knowledge of skating, i can promise you that at the end of two sessions, you will be able to move around comfortably. They also have hover board. The price for a session for skating is #2000 which is 45minutes while for hover board is #1000 which is 10 minutes. They also have for those who wish to be a member which is the membership fee but you can full details on that when you go to SkateCity Ng. SkateCity Ng teaches both children and adults.",
  },
  {
    id: 4,
    name: "Cobi O",
    starActive: [1, 2, 3, 4, 5],
    starInactive: [],
    review:
      "This is an interesting place to visit and take children as well.They tend to fall all over the place but still have fun..Instructors who guide and watch out for the kids are always present and if you want your kid to get even better, they offer coaching lessons.It is a nice place to hold birthday parties and they have a selection of kid friendly as well as adult food/snacks.. Candy floss, milk shakes, popcorn, freshly squeezed fruit juices and smoothies are some things you can get here....",
  },
  {
    id: 5,
    name: "Precious Ofili",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "Took my kids to Skate city for the first time yesterday to celebrate my son's birthday. They had so much fun. They were really excited to get all geared up and try roller skating for the first time.They also really enjoyed riding the hoover board.Skate city has really nice and professional instructors to help beginners and others who need any sort of help.They have membership packages as well with discounted rates on their activities. Adults aren't left out of the fun so go check them out!",
  },
  {
    id: 6,
    name: "Patrick Daudu",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "Skate city is strategically located in Maryland mall. Skating at skate city is a whole experience of fun, Excitement and Entertainment on its own. The Customer service is top notch as they give you are a one on one attention. There are different sizes of skateboards and hover board. The environment is quite unique and well taken care of. The road leading to this place is very accessible, there is a fire assembly point and enough parking lot the parking lot is also wheelchair accessible as well as the entrance. There is adequate security and CC TV is installed around. The rest room is quite neat too. Its a play recommended for family fun time.",
  },
  {
    id: 7,
    name: "Abdulazeez Makinde",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "Good experience over there if you like fun and doing something really skatie just how you use to imagine  it will be....  Good  refreshment also it doesn't go wrong",
  },
  {
    id: 8,
    name: "Yemi Ajibade",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "SkateCity is a decent skating place. I wish the place has better lighting though. They didn’t have inline skates for adults, only skate shoes (4 wheeled). The music is good. Overall, it’s a nice place to try out skating at",
  },
  {
    id: 9,
    name: "Quadrigold Adenuga",
    starActive: [1, 2, 3, 4],
    starInactive: [1],
    review:
      "A place of fabulous beauty. A fairly decent area of ​​the skating rink itself, in the center of which there is a beautiful Christmas tree. There were a lot of people during our visit, but everyone rides strictly counterclockwise and does not particularly interfere with each other. For beginners, there are special coasters in the form of bears, which you can hold on to while riding. Everything is organized quite well, I liked it, I recommend to visit.",
  },
];
