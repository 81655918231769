import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import MobileMenu from "../../components/mobileMenu/mobileMenu";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Footer from "../../components/footer/footer";
import EmailSubscribe from "../../components/emailSubscribe/emailSubscribe";
import EmailResponse from "../../components/emailResponse/emailResponse";
import Section6 from "./section6";

function LandingPage() {
  const ref = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [emailResponseOpen, setEmailResponseOpen] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const changeBackgroundRef = useRef(null);

  useEffect(() => {
    document.title = "Skate City • Home";
    window.scrollTo({ top: 0, behavior: "auto" });

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { root: null, threshold: 0.2 } // Triggers when 30% of the section is visible
    );

    if (changeBackgroundRef.current) {
      observer.observe(changeBackgroundRef.current);
    }

    return () => {
      if (changeBackgroundRef.current) {
        observer.unobserve(changeBackgroundRef.current);
      }
    };
  }, []);

  return (
    <div>
      {mobileMenuOpen && <MobileMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />}
      {emailResponseOpen && (
        <EmailResponse setEmailResponseOpen={setEmailResponseOpen} isErrorResponse={isErrorResponse} />
      )}
      <Header setMobileMenuOpen={setMobileMenuOpen} isIntersecting={isIntersecting} />
      <section ref={changeBackgroundRef}>
        <Section1 scrollDownRef={ref} />
      </section>
      <section ref={ref}>
        <Section2 />
      </section>
      <section>
        <Section3 />
      </section>
      <section>
        <Section4 />
      </section>
      <section>
        <Section5 />
      </section>
      <section>
        <Section6 />
      </section>
      <section>
        <EmailSubscribe
          emailResponseOpen={emailResponseOpen}
          setEmailResponseOpen={setEmailResponseOpen}
          setIsErrorResponse={setIsErrorResponse}
        />
      </section>
      <Footer />
    </div>
  );
}

export default LandingPage;
