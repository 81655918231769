import React from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../../components/buttons/buttons";
import { ReactComponent as DotsExperienceSectionDesktop } from "../../assets/svg2/dots-experience-section-desktop.svg";
import { ReactComponent as DotsExperienceSectionMobile } from "../../assets/svg2/dots-experience-section-mobile.svg";
import { ReactComponent as SkateCityText } from "../../assets/svg2/skate-city-text.svg";

function Section6() {
  const { width } = useWindowResize();

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="w-full h-[560px] my-[40px] bg-skatecityng-background-white">
        <div className="absolute z-10 w-full h-[560px] px-[80px] flex items-center justify-center">
          <div className="w-full flex items-center justify-between">
            <div className="w-[400px] h-[239px] ">
              <p className="text-[40px] leading-[100%] tracking-wider font-NewyearGoo text-skatecityng-purple">
                More Ways to Experience
              </p>
              <div className="mt-[12px]">
                <SkateCityText className="w-[245.6px] h-[45px] " />
              </div>
              <p className="mt-[40px] text-[16px] leading-[160%] font-OpenSansRegular">
                Why wait? Join us today and experience the thrill of SkateCity, we provide it all, come roll with it!
              </p>
            </div>

            <div className="w-[350px] h-[360px] p-[40px] rounded-[8px] border-[0.5px] flex items-center justify-center border-[#BBBBBB] bg-[#F5F5F9] ">
              <div>
                <p className="text-[40px] leading-[140%] tracking-wide font-NewyearGoo text-skatecityng-purple">
                  PARTY!
                </p>
                <p className="mt-[4px] text-[16px] leading-[160%] font-OpenSansRegular">
                  Make every occasion unforgettable! Birthdays, school trips or corporate event, we provide a vibrant
                  space with catering options to make it unique
                </p>
                <Buttons
                  title="Make Enquiries"
                  text="Make Enquiries"
                  className="w-[167px] h-[50px] text-center mt-[40px] rounded-[100px]
                  border-skatecityng-purple text-skatecityng-purple border-[1px] text-[16px] leading-[26px] font-OpenSansSemiBold"
                  // onClick={() => previousSlide()}
                />
              </div>
            </div>

            <div className="w-[350px] h-[360px] p-[40px] rounded-[8px] border-[0.5px] flex items-center justify-center border-skatecityng-blue bg-skatecityng-blue-plush ">
              <div>
                <p className="text-[40px] leading-[140%] tracking-wide font-NewyearGoo text-skatecityng-blue">SHOP!</p>
                <p className="mt-[4px] text-[16px] leading-[160%] font-OpenSansRegular">
                  Take the fun home with SkateCity’s exclusive merch! From skates and gear to playful accessories, find
                  everything you need to roll in style here
                </p>
                <Buttons
                  title="Shop Merchandise"
                  text="Shop Merchandise"
                  className="w-[191px] h-[50px] text-center mt-[40px] rounded-[100px]
                  border-skatecityng-blue text-skatecityng-blue border-[1px] text-[16px] leading-[26px] font-OpenSansSemiBold"
                  // onClick={() => previousSlide()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <DotsExperienceSectionDesktop className="bg-cover relative bg-repeat w-[1134px] h-[560px]" />
        </div>
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="w-full h-[1086px] my-[40px] bg-skatecityng-background-white">
        <div className="absolute z-10 w-full h-[1086px] px-[24px] flex items-center justify-center">
          <div className="h-full flex flex-col justify-evenly">
            <div className="w-[px] h-[183px] ">
              <p className="text-[32px] leading-[100%] tracking-wider font-NewyearGoo text-skatecityng-purple">
                More Ways to Experience
              </p>
              <div className="mt-[8px]">
                <SkateCityText className="w-[201.94px] h-[37px] " />
              </div>
              <p className="mt-[24px] text-[13px] leading-[160%] font-OpenSansRegular">
                Why wait? Join us today and experience the thrill of SkateCity, we provide it all, come roll with it!
              </p>
            </div>

            <div className="w-[350px] h-[303px] p-[40px] rounded-[8px] border-[0.5px] flex items-center justify-center border-[#BBBBBB] bg-[#F5F5F9]">
              <div>
                <p className="text-[32px] leading-[140%] tracking-wide font-NewyearGoo text-skatecityng-purple">
                  PARTY!
                </p>
                <p className="mt-[4px] text-[13px] leading-[160%] font-OpenSansRegular">
                  Make every occasion unforgettable! Birthdays, school trips or corporate event, we provide a vibrant
                  space with catering options to make it unique
                </p>
                <Buttons
                  title="Make Enquiries"
                  text="Make Enquiries"
                  className="w-[145px] h-[50px] text-center mt-[40px] rounded-[100px]
                border-skatecityng-purple text-skatecityng-purple border-[1px] text-[13px] leading-[26px] font-OpenSansSemiBold"
                  // onClick={() => previousSlide()}
                />
              </div>
            </div>

            <div className="w-[350px] h-[303px] p-[40px] rounded-[8px] border-[0.5px] flex items-center justify-center border-skatecityng-blue bg-skatecityng-blue-plush ">
              <div>
                <p className="text-[32px] leading-[140%] tracking-wide font-NewyearGoo text-skatecityng-blue">SHOP!</p>
                <p className="mt-[4px] text-[13px] leading-[160%] font-OpenSansRegular">
                  Take the fun home with SkateCity’s exclusive merch! From skates and gear to playful accessories, find
                  everything you need to roll in style here
                </p>
                <Buttons
                  title="Shop Merchandise"
                  text="Shop Merchandise"
                  className="w-[164px] h-[50px] text-center mt-[40px] rounded-[100px]
                border-skatecityng-blue text-skatecityng-blue border-[1px] text-[13px] leading-[26px] font-OpenSansSemiBold"
                  // onClick={() => previousSlide()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <DotsExperienceSectionMobile className="bg-cover relative bg-repeat w-full h-[1086px]" />
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default Section6;
