import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../../components/buttons/buttons";
import { ReactComponent as SkateCityBigLogo } from "../../assets/svg2/skate-city-big-logo.svg";
import { ReactComponent as SkateCityText } from "../../assets/svg2/skate-city-text.svg";

function Section1() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div>
        <div className="w-full h-[790px]">
          <div className="flex items-center justify-between">
            <div className="w-[200px] h-[515px] ml-[80px]">
              <SkateCityBigLogo className="mt-[46px] mb-[24px]" />
              <div className="flex">
                <p className="font-NewyearGoo text-[72px] mr-6 tracking-wider leading-[80.42px] text-skatecityng-purple">
                  Eat!
                </p>
                <p className="font-NewyearGoo text-[72px] tracking-wider leading-[80.42px] text-skatecityng-purple">
                  Drink!
                </p>
              </div>
              <div className="flex">
                <p className="font-NewyearGoo mr-6 text-[72px] tracking-wider leading-[80.42px] text-skatecityng-purple">
                  Play!
                </p>
                <p className="font-NewyearGoo text-[72px] tracking-wider leading-[80.42px] text-skatecityng-purple">
                  Repeat!
                </p>
              </div>

              <div className="flex items-center justify-center h-[34px] w-[550px] my-[24px] ">
                <p className="mr-[8px] font-TwCenMT text-[24px] leading-[26.13px] text-skatecityng-font-black">
                  Experience the ultimate thrill on wheels at
                </p>
                <SkateCityText />
              </div>

              <Buttons
                title="Explore Activities"
                text="Explore Activities"
                className="w-[210px] h-[50px] rounded-[100px] leading-[26px] text-[16px]
                  text-skatecityng-background-white font-OpenSansSemiBold bg-skatecityng-blue 
                  hover:border-[1px] hover:border-skatecityng-purple"
                onClick={() => navigate(NonAuthRoutes.play)}
              />
            </div>

            <video
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-[790px] -z-10 right-0 relative object-cover css-fade-left"
            >
              <source
                src="https://res.cloudinary.com/debmx1cb4/video/upload/c_fill,q_auto:best,f_auto/v1739637357/skate-city-ng-video_tugh2j.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        {/* <HeroDots className="bg-cover relative bg-repeat" /> */}
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div>
        <div className="w-full h-[790px]">
          <div className="flex items-center justify-between">
            <div className="w-[50px] h-[438px] mt-[85px] ml-[24px]">
              <SkateCityBigLogo className="h-[112px] w-[80px] mb-[20px]" />
              <div className="flex">
                <p
                  className="font-NewyearGoo mr-6 text-[40px]  tracking-wider leading-[44.68px] text-skatecityng-purple
                  [text-shadow:_-1px_-1px_0_white,1px_-1px_0_white,-1px_1px_0_white,1px_1px_0_white]
              "
                >
                  Eat!
                </p>
                <p
                  className="font-NewyearGoo text-[40px] tracking-wider leading-[44.68px] text-skatecityng-purple
                  [text-shadow:_-1px_-1px_0_white,1px_-1px_0_white,-1px_1px_0_white,1px_1px_0_white]
                "
                >
                  Drink!
                </p>
              </div>
              <div className="flex">
                <p
                  className="font-NewyearGoo mr-6 text-[40px] tracking-wider leading-[44.68px] text-skatecityng-purple
                  [text-shadow:_-1px_-1px_0_white,1px_-1px_0_white,-1px_1px_0_white,1px_1px_0_white]
                "
                >
                  Play!
                </p>
                <p
                  className="font-NewyearGoo text-[40px] tracking-wider leading-[44.68px] text-skatecityng-purple
                  [text-shadow:_-1px_-1px_0_white,1px_-1px_0_white,-1px_1px_0_white,1px_1px_0_white]
                "
                >
                  Repeat!
                </p>
              </div>

              <div className="w-[350px] my-[20px]">
                <p
                  className="font-TwCenMT text-[18px] leading-[19.6px] text-skatecityng-font-black
                  [text-shadow:_-1px_-1px_0_white,1px_-1px_0_white,-1px_1px_0_white,1px_1px_0_white]
                "
                >
                  Experience the ultimate thrill on wheels at
                </p>
                <SkateCityText className="w-[130.99px] h-[24px] mt-[8px]" />
              </div>

              <Buttons
                title="Explore Activities"
                text="Explore Activities"
                className="w-[181px] h-[50px] mt-[6px] rounded-[100px] leading-[26px] text-[16px]
                  text-skatecityng-background-white font-OpenSansSemiBold bg-skatecityng-blue 
                  hover:border-2 hover:border-white"
                onClick={() => navigate(NonAuthRoutes.play)}
              />
            </div>

            <video
              autoPlay
              muted
              loop
              playsInline
              className="w-[300px] h-[790px] -z-10 relative object-cover css-fade-left"
            >
              <source
                src="https://res.cloudinary.com/debmx1cb4/video/upload/c_fill,q_auto:best,f_auto/v1739637357/skate-city-ng-video_tugh2j.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}
export default Section1;
