import React from "react";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/buttons/buttons";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import SkateShoesImage from "../../assets/img2/skate-shoes.png";
import MunchImage from "../../assets/img2/munch.png";
import GamesImage from "../../assets/img2/games.png";

function ReusableComponentDesktop({ image, cardName, cardDescription, buttonText, className, route }) {
  const navigate = useNavigate();

  return (
    <div className="h-[554px] w-[480px]  ">
      <div
        className={`${className} h-[300px] w-full flex items-center justify-center shadow-[0px_4px_40px_0px_#13131340]`}
      >
        {image}
      </div>
      <div className="h-[254px] flex items-center justify-center border-b-[0.5px] border-r-[0.5px] border-[#BBBBBB] bg-skatecityng-blue-plush">
        <span>
          <p className="flex items-center justify-center text-[40px] leading-[54px] tracking-wider font-NewyearGoo text-skatecityng-blue-dark">
            {cardName}
          </p>
          <p className="flex items-center justify-center text-center w-[352px] mt-[8px] text-[15px] leading-[25.6px] font-OpenSansRegular text-skatecityng-font-black">
            {cardDescription}
          </p>

          <div className="flex items-center justify-center">
            <Buttons
              title={buttonText}
              text={buttonText}
              className="w-[168px] h-[50px] rounded-[100px] leading-[26px] text-[16px] mt-[24px] border-[1px] font-OpenSansSemiBold
                  text-skatecityng-blue  border-skatecityng-blue hover:border-skatecityng-purple "
              onClick={() => navigate(route)}
            />
          </div>
        </span>
      </div>
    </div>
  );
}

function ReusableComponentMobile({ image, cardName, cardDescription, buttonText, className, route }) {
  const navigate = useNavigate();

  return (
    <div className="h-[525px] w-full  ">
      <div
        className={`${className} h-[300px] w-full flex items-center justify-center shadow-[0px_4px_40px_0px_#13131340]`}
      >
        {image}
      </div>
      <div className="h-[225px] flex items-center justify-center border-b-[0.5px] border-r-[0.5px] border-[#BBBBBB] bg-skatecityng-blue-plush">
        <span>
          <p className="flex items-center justify-center text-[34px] leading-[140%] tracking-wider font-NewyearGoo text-skatecityng-blue-dark">
            {cardName}
          </p>
          <p className="flex items-center justify-center text-center w-[352px] mt-[8px] text-[13px] leading-[160%] font-OpenSansRegular text-skatecityng-font-black">
            {cardDescription}
          </p>

          <div className="flex items-center justify-center">
            <Buttons
              title={buttonText}
              text={buttonText}
              className="w-[145px] h-[42px] rounded-[100px] leading-[100%] text-[13px] mt-[24px] border-[1px] font-OpenSansSemiBold
                  text-skatecityng-blue  border-skatecityng-blue hover:border-skatecityng-purple "
              onClick={() => navigate(route)}
            />
          </div>
        </span>
      </div>
    </div>
  );
}

function Section2() {
  const { width } = useWindowResize();

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="flex">
        <ReusableComponentDesktop
          image={<img src={SkateShoesImage} className="w-[200px] h-[191px]" alt="Skate Shoes" />}
          cardName="Skate!"
          cardDescription="Skating ramps, rails and bowls for all skill levels. Equipment rental available"
          buttonText="Explore Skating"
          className="bg-gradient-to-b from-[#028DD6] to-[#02669A]"
          route={NonAuthRoutes.play}
        />
        <ReusableComponentDesktop
          image={<img src={MunchImage} className="w-[200px] h-[191px]" alt="Munch" />}
          cardName="Munch!"
          cardDescription="Tasty burgers, snacks and refreshments to fuel your adventure"
          buttonText="Browse Menu"
          className="bg-gradient-to-b from-[#028DD6] to-[#02669A]"
          route={NonAuthRoutes.munch}
        />
        <ReusableComponentDesktop
          image={<img src={GamesImage} className="w-[200px] h-[191px]" alt="Games" />}
          cardName="Play!"
          cardDescription="Gaming consoles, VR experiences, and arcade classics for endless fun"
          buttonText="Browse Activities"
          className="bg-gradient-to-b from-[#028DD6] to-[#02669A]"
          route={NonAuthRoutes.play}
        />
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="">
        <ReusableComponentMobile
          image={<img src={SkateShoesImage} className="w-[200px] h-[191px]" alt="Skate Shoes" />}
          cardName="Skate!"
          cardDescription="Skating ramps, rails and bowls for all skill levels. Equipment rental available"
          buttonText="Explore Skating"
          className="bg-gradient-to-b from-[#028DD6] to-[#02669A]"
          route={NonAuthRoutes.play}
        />
        <ReusableComponentMobile
          image={<img src={MunchImage} className="w-[200px] h-[191px]" alt="Munch" />}
          cardName="Munch!"
          cardDescription="Tasty burgers, snacks and refreshments to fuel your adventure"
          buttonText="Browse Menu"
          className="bg-gradient-to-b from-[#028DD6] to-[#02669A]"
          route={NonAuthRoutes.munch}
        />
        <ReusableComponentMobile
          image={<img src={GamesImage} className="w-[200px] h-[191px]" alt="Games" />}
          cardName="Play!"
          cardDescription="Gaming consoles, VR experiences, and arcade classics for endless fun"
          buttonText="Browse Activities"
          className="bg-gradient-to-b from-[#028DD6] to-[#02669A]"
          route={NonAuthRoutes.play}
        />
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}

export default Section2;
