import React, { createContext, useState, useEffect, useMemo } from "react";
import localStorageHandler from "./localStorageHandler";

export const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // Function to update cart count from localStorage
    const updateCartCount = () => {
      const storedCart = JSON.parse(localStorageHandler.getCart()) || [];
      setCartCount(storedCart.length);
    };

    // Load initial count on mount
    updateCartCount();

    // Listen for changes in localStorage
    window.addEventListener("storage", updateCartCount);

    return () => {
      window.removeEventListener("storage", updateCartCount);
    };
  }, []);

  // Memoize context value to avoid re-renders
  const contextValue = useMemo(() => ({ cartCount, setCartCount }), [cartCount]);

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
}
