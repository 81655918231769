import React from "react";

function Inputs({
  label,
  type,
  placeholder,
  value,
  onChange,
  className,
  title,
  titleStyle,
  disabled,
  minLength,
  maxLength,
  pattern,
  onInput,
}) {
  return (
    <div>
      <label htmlFor={label}>
        {title && (
          <p
            className={`${titleStyle} mb-[6px] font-OpenSansRegular leading-[100%] opacity-[70%] text-skatecityng-font-black`}
          >
            {title}
          </p>
        )}
        <input
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          title={title}
          id={label}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onInput={onInput}
          className={`${className} text-[16px] leading-[100%] py-3 px-5 appearance-none focus:outline-none`}
        />
      </label>
    </div>
  );
}

export default Inputs;
