/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Buttons from "../../components/buttons/buttons";
import { useWindowResize } from "../../helpers/useWindowResize";
import { reviews } from "./types.dto";
import { ReactComponent as SkateCityText } from "../../assets/svg2/skate-city-text.svg";
import { ReactComponent as ArrowIconWhite } from "../../assets/svg2/arrow-icon-white.svg";
import { ReactComponent as User } from "../../assets/svg/user.svg";
import { ReactComponent as StarActive } from "../../assets/svg/star-active.svg";
import { ReactComponent as StarInactive } from "../../assets/svg/star-inactive.svg";
import { ReactComponent as NextArrow } from "../../assets/svg/arrow-next.svg";
import { ReactComponent as PreviousArrow } from "../../assets/svg/arrow-previous.svg";

function Section5() {
  const { width } = useWindowResize();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentReviewersName, setCurrentReviewersName] = useState("");
  const [currentReviewersReview, setCurrentReviewersReview] = useState("");
  const slideLength = reviews.length;

  /** Handles Next Slide */
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  /** Handles Previous Slide */
  const previousSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  /** Diplay On Desktop View */
  const desktopView = () => {
    return (
      <div className="w-full py-[100px] bg-skatecityng-blue-darker">
        <div>
          <div className="flex items-center justify-center">
            <p className="mr-[4px] text-center font-OpenSansRegular text-[16px] leading-[100%] text-skatecityng-font-white">
              The buzz about
            </p>
            <span className="ml-[4px]">
              <SkateCityText />
            </span>
          </div>
          <p className="mt-[40px] mb-[16px] text-center text-skatecityng-blue font-NewyearGoo text-[40px] leading-[100%] tracking-wider">
            What our customers are saying
          </p>
          <p className="mb-[48px] text-center font-OpenSansRegular text-[16px] leading-[160%] px-[330px] text-skatecityng-font-white">
            Our satisfied customers share their positive experiences and feedback from their time at Skate City. Read
            their stories and see why our center is the ultimate destination for entertainment and fun.
          </p>
        </div>

        <div className="flex items-center justify-center">
          <div className="w-full absolute z-10 flex justify-between px-[180px]">
            <Buttons
              title="Previous"
              icon={<ArrowIconWhite className="left-0 w-[30px] h-[30px] " />}
              onClick={() => previousSlide()}
            />
            <Buttons
              title="Next"
              icon={<ArrowIconWhite className="right-0 rotate-180 w-[30px] h-[30px] " />}
              onClick={() => nextSlide()}
            />
          </div>
          <div className="flex items-center justify-center">
            <div className="w-[960px] h-[381px] rounded-[7.5px] bg-white shadow-box">
              <div className="flex items-center justify-center">
                <User className="bg-[#DCEEFB] w-12 h-12 rounded-full mt-[30px]" />
              </div>
              <p className="mt-[18px] text-center font-PoppinsBold text-[15px] leading-[24px] text-skatecityng-purple">
                {reviews[currentSlide].name}
              </p>
              <div className="flex items-center justify-center">
                <div className="w-[99px] mt-[18px] flex items-center justify-between ">
                  {reviews[currentSlide].starActive.map(index => (
                    <div key={index}>
                      <StarActive className="" />
                    </div>
                  ))}
                  {reviews[currentSlide].starInactive.map(index => (
                    <div key={index}>
                      <StarInactive className="" />
                    </div>
                  ))}
                </div>
              </div>
              <p className="mb-6 mt-8 text-center font-PoppinsRegular text-[15px] leading-[24px] px-[100px]">
                {reviews[currentSlide].review}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** Diplay On Mobile View */
  const mobileView = () => {
    return (
      <div className="w-full py-[100px] bg-skatecityng-blue-darker">
        <div>
          <div className="mb-[36px] flex items-center justify-center">
            <p className="text-center font-OpenSansRegular text-[13px] leading-[100%] text-skatecityng-font-white">
              The buzz about
            </p>
            <span className="ml-[4px]">
              <SkateCityText />
            </span>
          </div>
          <p className="px-[24px] mb-[16px] text-center text-skatecityng-blue font-NewyearGoo text-[32px] leading-[100%] tracking-wider">
            What our customers are saying
          </p>
          <p className="px-[24px] mb-[48px] text-center font-OpenSansRegular text-[13px] leading-[160%] text-skatecityng-font-white">
            Our satisfied customers share their positive experiences and feedback from their time at Skate City. Read
            their stories and see why our center is the ultimate destination for entertainment and fun.
          </p>
        </div>
        <div className="flex items-center justify-center px-4">
          <div className="w-[960px] py-[30px] rounded-[8px] bg-white shadow-box">
            <div className="flex items-center justify-center">
              <User className="bg-[#DCEEFB] w-12 h-12 rounded-full mt-[30px]" />
            </div>
            <p className="mt-[18px] text-center font-PoppinsBold text-[12px] leading-[24px] text-skatecityng-purple">
              {reviews[currentSlide].name}
            </p>
            <div className="flex items-center justify-center">
              <div className="w-[99px] mt-[18px] flex items-center justify-between">
                {reviews[currentSlide].starActive.map(index => (
                  <div key={index}>
                    <StarActive className="" />
                  </div>
                ))}
                {reviews[currentSlide].starInactive.map(index => (
                  <div key={index}>
                    <StarInactive className="" />
                  </div>
                ))}
              </div>
            </div>
            <p className="mb-6 mt-8 text-center font-PoppinsRegular text-[12px] leading-[23px] px-[20px]">
              {reviews[currentSlide].review}
            </p>
          </div>
        </div>
        <div className="w-full mt-[30px] flex items-center justify-center">
          <span>
            <div className="w-[104px] flex items-center justify-between">
              <Buttons
                title="Previous"
                onClick={() => previousSlide()}
                icon={<ArrowIconWhite className="left-0 w-[40px]" />}
              />
              <Buttons
                title="Next"
                onClick={() => nextSlide()}
                icon={<ArrowIconWhite className="right-0 rotate-180 w-[40px]" />}
              />
            </div>
          </span>
        </div>
      </div>
    );
  };

  return <div>{width > 1060 ? desktopView() : mobileView()}</div>;
}
export default Section5;
