const localStorageHandler = {
  setCart(cart) {
    localStorage.setItem("cart", cart);
  },
  getCart() {
    return localStorage.getItem("cart");
  },
  setCustomerDetails(details) {
    return localStorage.setItem("customerDetails", details);
  },
  getCustomerDetails() {
    return localStorage.getItem("customerDetails");
  },
  setOrderSummary(orderSummary) {
    return localStorage.setItem("orderSummary", orderSummary);
  },
  getOrderSummary() {
    return localStorage.getItem("orderSummary");
  },
};

export default localStorageHandler;
